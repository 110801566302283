import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Container from 'common/src/components/UI/Container';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import SectionHeading from '../SectionHeading';
import SectionWrapper, { ContentWrapper, FaqItem } from './cookies.style';
import { useIntl } from "gatsby-plugin-intl";

const Cookies = ({
  lang
}) => {
  const data = useStaticQuery(graphql`
    query {
      hostingModernJson {
        faqs_PT{
          id
          title
          desc
        }
        faqs_EN{
          id
          title
          desc
        }
      }
    }
  `);
  const intl = useIntl();
  const faqComponent = (faq) => {
    return(
      <Fade key={faq.id} up delay={100 * faq.id}>
        <FaqItem>
          <Heading as="h4" content={faq.title} />
          <Text content={faq.desc} />
        </FaqItem>
      </Fade>
    )
  }
  return (
    <SectionWrapper id="cookies">
      <Container>
        <Fade up delay={100}>
          <SectionHeading
            title={`${intl.locale ==='en'? 'Cookies Policy': 'Política de Cookies'}`}
          />
        </Fade>
        <ContentWrapper>
          { intl.locale === "en" ?
            data.hostingModernJson.faqs_EN.map(faq => (
              faqComponent(faq)
            ))
            :
            data.hostingModernJson.faqs_PT.map(faq => (
              faqComponent(faq)
            ))
          }
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Cookies;
